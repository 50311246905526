import React from 'react'
import Layout from '../components/Layout'
import Footer from '../components/Footer'

const NotFoundPage = () => (
  <>
    <Layout title={'404'} transparent={false}>
      <div>
        <h1 style={{padding: '200px 0'}}>NOT FOUND</h1>
        <p></p>
      </div>
    </Layout>
    <Footer/>
  </>
)

export default NotFoundPage
